<template>
  <div>
    <a-row>
      <a-col :span="12">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-10"
        />
      </a-col>
      <a-col :span="4">
        <span>共{{ data.totalQuantity }}项</span>
      </a-col>
      <a-col :span="4" v-if="orderType === 'add_item'">
        <span style="color:green">小计：{{ data.sub_price }}元</span>
      </a-col>
      <a-col :span="4">
        <span style="color:green">金额：{{ data.totalPrice }}元</span>
      </a-col>
    </a-row>
    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="orderType === 'add_item' ? addColumns : columns"
      :data-source="goodsInfo"
      :pagination="false"
      :loading="loading"
      row-key="id"
    >
      <span slot="elegiac_information" slot-scope="text, record">
        <a-space>
          <a @click="showElegiacInformationModal(record)" v-if="record.type_slug === 'wreath'">点击查看</a>
        </a-space>
      </span>
    </a-table>
    <show-elegiac-information
      v-if="isShowElegiac"
      :visible.sync="isShowElegiac"
      :elegiac-information="elegiacInformation"
    />
  </div>
</template>

<script>
import { findSubscribeOrderProducts } from '@/api/order'
import { formatCurrency } from '@/utils/filter'

export default {
  name: 'SubscribeOrderProduct',
  props: {
    id: {
      type: Number,
      required: true
    },
    orderType: {
      type: String,
      required: true
    }
  },
  components: { ShowElegiacInformation: () => import('@/views/orders/tenant/ElegiacInformation') },
  data() {
    return {
      data: {},
      goodsInfo: [],
      isShowElegiac: false,
      loading: true,
      elegiacInformation: {},
      orderId: 0
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '序号',
          dataIndex: 'index',
          fixed: 'left'
        },
        {
          title: '品名',
          dataIndex: 'product_name'
        },
        {
          title: '型号',
          dataIndex: 'model_name'
        },
        {
          title: '类型',
          dataIndex: 'type_name'
        },
        {
          title: '规格',
          dataIndex: 'product_specification_name'
        },
        {
          title: '价格',
          dataIndex: 'price_display'
        },
        {
          title: '数量',
          dataIndex: 'count'
        },
        {
          title: '金额(元)',
          dataIndex: 'fee',
          customRender: formatCurrency
        },
        {
          title: '挽联信息',
          width: 100,
          fixed: 'right',
          dataIndex: 'elegiac_information',
          scopedSlots: { customRender: 'elegiac_information' }
        }
      ]
    },
    addColumns() {
      return [
        {
          title: '序号',
          dataIndex: 'index',
          fixed: 'left'
        },
        {
          title: '品名',
          dataIndex: 'product_name'
        },
        {
          title: '型号',
          dataIndex: 'model_name'
        },
        {
          title: '类型',
          dataIndex: 'type_name'
        },
        {
          title: '规格',
          dataIndex: 'product_specification_name'
        },
        {
          title: '价格',
          dataIndex: 'price_display'
        },
        {
          title: '数量',
          dataIndex: 'count'
        },
        {
          title: '小计(元)',
          dataIndex: 'fee',
          customRender: formatCurrency
        },
        {
          title: '退货数量',
          dataIndex: 'back_count'
        },
        {
          title: '实收金额(元)',
          dataIndex: 'receive_fee',
          customRender: formatCurrency
        },
        {
          title: '挽联信息',
          width: 100,
          fixed: 'right',
          dataIndex: 'elegiac_information',
          scopedSlots: { customRender: 'elegiac_information' }
        }
      ]
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      findSubscribeOrderProducts(this.id, { order_type: this.orderType }).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.goodsInfo = res.data.goodsInfo
        }
        this.loading = false
      })
    },
    showElegiacInformationModal(record) {
      this.elegiacInformation = record.moreInfo
      this.isShowElegiac = true
    }
  }
}
</script>
